import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageCategorias from './pages/categorias.js'
import PageCategoria from './pages/pageCategoria.js'
import PageVotacao from './pages/pageVotacao'
import PageSuperJuri from './pages/superJuri.js'
import PageSuperJuriSingle from './pages/superJuriSingle.js'
import PageRegulamento from './pages/faq.js'
import PageLogin from "./pages/Login";
import pageIndicacao from './pages/pageIndicacao.js'
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import animationNumbers from './pages/counter.js'
import getApiAtletas from './pages/get_api_atletas'
import pageVotacao from './pages/pageVotacao' 
import pageIndicacaoFase2 from './pages/pageIndicacao-fase-2'
import pageIndicacaoFase2SuperJuri from './pages/pageIndicacao-super-juri-fase-2'



getApiAtletas()
// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component
console.log('🟢 06');

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
animationNumbers()


// Pages
PageHome()
PageCategorias()
PageCategoria()
PageRegulamento()
PageSuperJuri()
PageSuperJuriSingle()
pageVotacao()

// descomentar na fase 1 
// pageIndicacao()
pageIndicacaoFase2()
pageIndicacaoFase2SuperJuri()

PageLogin()?.init()

// utils logs
logs()



